<template lang="pug">
  .app-checklist
    .block-checklist
      .block-checklist__item.unselectable(
        v-for="(option, index) in options"
        :key="index"
        :class="{'block-checklist__item--disabled': disabled, 'block-checklist__item--selected': value === option[slugKey], 'block-checklist__item--error': error}"
        @click="$emit('input', option[slugKey])"
      )
        p.text {{ slugValue ? option[slugValue] : option }}
        transition(name="fade")
          img(v-if="value === option[slugKey]" :src="require('@/assets/img/ui/circle.svg')")
    span.error-message {{ errorMessages }}
</template>

<script>
export default {
  name: 'BlockChecklist',

  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      required: true
    },
    selected: Boolean,
    slugKey: String,
    slugValue: String,
    error: Boolean,
    errorMessages: String,
    disabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
.app-checklist {
  padding-bottom: 15px;

  .block-checklist {
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;

    &__ {
      &item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: 1px solid $border-color;
        margin-bottom: 12px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        &-- {
          &disabled {
            opacity: 0.6;
            pointer-events: none;
          }
          &selected {}
          &error {
            border-color: $error-color;
          }
        }

        p {
          font-family: $font;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 18px;
          display: flex;
          color: #161616;
        }
      }
    }
  }
}
</style>
